<template>
  <footer v-if="showFooter" :class="{ 'is-logged-in': isLoggedIn }">
    <router-link to="/about" class="navbar-item">About</router-link>
    <router-link to="/privacy" class="navbar-item">Privacy</router-link>
    <router-link to="/tos" class="navbar-item">Terms</router-link>
    <router-link to="/contact" class="navbar-item">Contact</router-link>
  </footer>
</template>
<script>
import { get } from "vuex-pathify"
import authorization from "@/mixins/authorization.js"

export default {
  name: "GlobalFooter",
  components: {},
  mixins: [authorization],
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  computed: {
    showFooter: get("ui/showFooter"),
  },
  methods: {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
}
</script>

<style lang="scss" scoped>
footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 1vh;
  padding-right: 20px;
  height: 20vh;
  background-color: $purple-grey;
}

.navbar-item {
  height: 40px;
  padding: 20px;
  color: #778dad;
  margin-bottom: 10px;
}

.navbar-item:hover {
  background-color: $purple-grey;
  color: mix(#778dad, $white, 50%);
  text-decoration: underline;
}

.nohover:hover {
  text-decoration: none;
  color: #778dad;
}

// probably don't want a large annoying footer taking up screen space
.is-logged-in {
  border-top: 1px solid $primary;
  height: 60px;
  background-color: $white;
  color: $primary;

  .navbar-item {
    color: mix($primary, $grey-darker, 10%);
    margin-bottom: 2px;
  }

  .navbar-item:hover {
    background-color: $white;
  }
}
</style>
